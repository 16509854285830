import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Love from '../../../images/love_popart.svg';

// flex-end - always be at bottom of page
const StyledFooter = styled.footer`
  align-self: flex-end;
  width: 100%;
  background-color: #CCD1D9;
  margin: 0px;
  padding-bottom: 16px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
`;

const StyledMadeWith = styled.p`
  font-size: 18px;
  width: 120px;
  text-align: right;
`;

const StyledLoveIn = styled.p`
  font-size: 18px;
  width: 120px;
  text-align: left;
`;

const StyledText = styled.p`
  font-size: 14px;
  margin: 0px;
`;

const StyledLove = styled(Love)`
  margin: 8px;
  width: 35px;
  height: 35px;
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <StyledDiv>
        <StyledMadeWith>
          {t('footer.madeWith')}
        </StyledMadeWith>
        <StyledLove />
        <StyledLoveIn>
          {t('footer.inBerlin')}
        </StyledLoveIn>
      </StyledDiv>
      <StyledDiv>
        <StyledText>
          {t('footer.disclaimer')}
        </StyledText>
      </StyledDiv>
    </StyledFooter>
  );
}

export default Footer;
