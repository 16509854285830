import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 8px 0px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: start;
  padding: 0px 32px;
  overflow: hidden;
`;

export const StyledButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#f0f3f4' : '#000000')};
  cursor: pointer;
  border-radius: 0px;
  border: none;
  min-width: 0px;
  width: 100%;
  max-width: 350px;
  height: 58px;
  padding: 0px;
  color: ${(props) => (props.disabled ? '#000000' : '#ffffff')};
`;

export const StyledButtonText = styled.p`
  margin: 0px 10px 0px 10px;
  align-self: center;
  font-size: 14px;
`;

export const StyledSectionHeader = styled.h2`
  margin: 10px 5px 0px 5px;
`;

export const StyledDisclaimerText = styled.p`
  margin: 0px 0px 4px 0px;
  font-size: 14px;
  cursor: pointer;
`;
