import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Header from './Shop/Header';
import Footer from './Shop/Footer';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh + 87px);
  height: 100%;
`;

// flex: 1 - expand to fill remaining space on screen
const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 64px);
  padding: 0px 32px 16px 32px;
  color: #232129;
`;

function ShopLayout({ children }) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('appName')}</title>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1" />
      </Helmet>
      <StyledDiv>
        <Header />
        <Main>
          {children}
        </Main>
        <Footer />
      </StyledDiv>
    </>
  );
}

export default ShopLayout;
