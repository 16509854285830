import * as React from 'react';
import styled from 'styled-components';
import Logo from '../../../images/karla_logo.svg';

const StyledHeader = styled.header`
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 105px;
`;

function Header() {
  return (
    <StyledHeader>
      <Logo />
    </StyledHeader>
  );
}

export default Header;
