import * as React from 'react';
import styled from 'styled-components';
import KarlaCheck from '../../images/karla_check.svg';
import KarlaMail from '../../images/karla_mail.svg';

const Root = styled.div`
  height: ${(props) => (props.isLarge ? '77px' : '60px')};
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;  
`;

export const StyledInput = styled.input`
  min-width: 0px;
  width: ${(props) => (props.hasSideIcon ? undefined : '100%')};
  max-width: 350px;
  background: ${(props) => (props.isMail ? '#F0F3F4' : '#F0F3F4')};
  border: none;
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  
  @media only screen and (max-width: 400px) {
    font-size: 20px;
  }

  ::placeholder {
    font-family: 'Sombra Regular';
    color: #8D9299;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    @media only screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
`;

const StyledCheck = styled(KarlaCheck)`
    min-width: 77px;
    width: 77px;
    height: 77px;
`;

const StyledMail = styled(KarlaMail)`
    min-width: 77px;
    width: 77px;
    height: 77px;
`;

function KarlaInput({
  disabled,
  innerRef,
  isMail,
  onKeyDown,
  placeholder,
  value,
  onChange,
  hasSideIcon,
}) {
  return (
    <Root isLarge={isMail || disabled}>
      {isMail && !disabled && <StyledMail />}
      <StyledInput
        type={isMail ? 'email' : 'text'}
        disabled={disabled}
        ref={innerRef}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={value}
        isMail={isMail}
        hasSideIcon={hasSideIcon}
      />
      {disabled && <StyledCheck />}
    </Root>
  );
}

export default KarlaInput;
